/*
 * File: CarOffersSummary.tsx
 * Project: autobuy-back-office
 * File Created: Wednesday, 30th August 2023 09:47:19
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Tuesday, 16th January 2024 10:13:21
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import { Offer } from "Type/offer.type";
import Styles from "./CarOffersSummary.module.css";
import translator from "MultiLanguage/TranslationWrapper";

type CarOffersSummary = {
    Offers: Offer[];
    oldOffer: boolean
}

const CarOfferSummary = (props: CarOffersSummary) => {
    const CarSummaryData = props.Offers.sort((offer1, offer2) => {
        let ret = offer2.Value - offer1.Value;
        if (ret === 0)
            return (new Date(offer2.UpdatedAt).getTime() - new Date(offer1.UpdatedAt).getTime());
        return ret;
    }).map((item, index) => {
        if (item.User)
            return (
                <div className={`${Styles.topOffer}`} key={`offer-${index}`}>
                    <span className="text-capitalize">{`${item.User.Company.Name} (${item.User.FirstName} ${item.User.LastName})`}</span>
                    <span> {item.Value} € </span>
                    {
                        props.oldOffer ?
                            <span>{new Date(item.UpdatedAt).toLocaleDateString()}</span>
                            : <span>{new Date(item.UpdatedAt).toLocaleString()}</span>
                    }
                    {
                        item.Gifted ?
                            <span>{translator.decode("dynamic.VALID")}</span>
                            : null
                    }
                    {
                        item.User.Commercial?.FirstName && item.User.Commercial?.LastName ?
                        <span className="text-capitalize">/ {item.User.Commercial.FirstName} {item.User.Commercial.LastName}</span>
                        : null
                    }
                </div>
            );
        return null;
    });

    return (
        <div className={`${Styles.offersDisplay}`} >
            {
                props.oldOffer ?
                    <h6 className="m-0">{translator.decode("dynamic.previous")}: </h6>
                    :<h6 className="m-0">{translator.decode("dynamic.offersDone")} </h6>
            }
            <div className={`${Styles.offers}`}>
                { CarSummaryData }
            </div>
        </div>
    )
}

export default CarOfferSummary;
