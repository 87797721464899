/*
 * File: Visuals.tsx
 * Project: autobuy-back-office
 * File Created: Friday, 4th August 2023 12:15:58
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Tuesday, 5th September 2023 09:27:19
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import BackArrow from "Content/BackArrow/BackArrow";
import { Fragment } from "react";
import translator from "MultiLanguage/TranslationWrapper";
import { useLoaderData } from "react-router-dom";
import CollectionVisuals from "./Collection/Collection";

function Visuals() {
    const loadData = useLoaderData() as {id: string, Name: string}[];
    const visualTitle = translator.decode("visuals.titles.title");

    return (
        <Fragment>
            <BackArrow />
            <h1 className="w-25 m-auto">{visualTitle}</h1>
            {
                loadData.map((item) => {
                    return (<CollectionVisuals name={item.Name} />);
                })
            }
        </Fragment>
    )
}

export default Visuals;
