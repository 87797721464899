/*
 * File: MyCarrier.tsx
 * Project: autobuy-back-office
 * File Created: Friday, 22nd September 2023 02:52:44
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Tuesday, 7th November 2023 06:28:11
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import Select from "react-select";
import { CarrierType } from "Type/carrier.type";
import { Fragment, useEffect, useRef, useState } from "react";
import { useLoaderData } from "react-router-dom";
import Styles from "Content/Carrier/MyCarrier.module.css";
import translator from "MultiLanguage/TranslationWrapper";
import TitlePage from "Content/HeadingPage/TitlePage/TitlePage";
import RouterPathResolver from "Config/Router/RouterPathResolver";
import ModalCarrier from "Content/Modals/ModalCarrier/ModalCarrier";
import CarrierTable from "./CarrierTable";
import CreateRate from "Content/Rate/CreateRate";
import RateService from "Services/Rate/RateService";
import { RateToSendType } from "Type/rate.type";

const CarrierList = () => {
    const loadData = useLoaderData() as CarrierType[];
    const [curCarrierID, setCurCarrierID] = useState<string>();
    const [carrierOption, setCarrierOption] = useState<{value: string, label: string} | null>(null);
    const [startingOption, setStartingOption] = useState<{value: number, label: string} | null>(null);
    const [endingOption, setEndingOption] = useState<{value: number, label: string} | null>(null);
    const [data, setData] = useState(loadData);
    const selectCarrierOpt = useRef<{value: string, label: string}[]>([]);
    const options = useRef<{value: number, label: string}[]>([]);
    const [showModalCarrier, setShowModalCarrier] = useState(false);
    const myActions = useRef(<button className="btn btn-primary" onClick={() => setShowModalCarrier(true)}> {translator.decode("dynamic.create")} {translator.decode("dynamic.carrier")} </button>);

    useEffect(() => {
        for (let i = 0; i < data.length; ++i)
            selectCarrierOpt.current.push({value: data[i].id, label: data[i].Name});
        for (let i = 1; i < 96; ++i)
            options.current.push({value: i, label: i.toString()});
    }, [data]);

    const closeCarrierModal = () => {
        setShowModalCarrier(false);
    };

    const findRate = (carrierID: string, startingState: number, endingState: number) => {
        const carrier = data.find((val) => val.id === carrierID);
        if (carrier === undefined)
            return {cost: undefined, comments: undefined};
        const rate = carrier.Rates.find((val) => val.StartingState === startingState && val.EndingState === endingState);
        if (rate === undefined)
            return {cost: undefined, comments: undefined};
        return {cost: parseInt(rate.Cost), comments: rate.Comment, rateID: rate.id};
    };

    const handleDeleteRate = (rateID: string, carrierID: string, startingState: number, endingState: number) => {
        let myCarrier: string = "";
        data.map(carrier => {if (carrier.id === carrierID) {return myCarrier = carrier.Name}});
        if (rateID)
            if (window.confirm(translator.decode("dynamic.confirmDeleteRate").replace("%D", startingState).replace("%A", endingState).replace("%T", myCarrier)))
                RateService.DeleteRate(rateID)
                .then(() => {
                    window.alert(translator.decode("carrier.message.delete"));
                })
                .catch(() => {
                    window.alert(translator.decode("carrier.message.errorDel"));
                })
    };

    const handleCreateRate = async (rate: {carrierID: string, startingState: number, endingState: number, cost: string, rateID?: string, comment?: string}) => {
        const dataToSend: RateToSendType = {
            id: rate.rateID,
            CarrierID: rate.carrierID,
            StartingState: rate.startingState,
            EndingState: rate.endingState,
            Cost: parseFloat(rate.cost),
            Comment: rate.comment
        };

        RateService.CreateRate(dataToSend)
        .then(rateReceived => {
            let carrier = data.find((val) => val.id === rate.carrierID)
            if (rate.rateID && carrier !== undefined) {
                let myRate = carrier.Rates.find(r => r.id === rate.rateID);
                if (myRate) {
                    myRate.Cost = rate.cost;
                    myRate.Comment = rate.comment ?? undefined;
                }
            } else if (carrier !== undefined)
                carrier.Rates.push({id: rateReceived.id, StartingState: rateReceived.StartingState, EndingState: rateReceived.EndingState, Cost: rateReceived.Cost, Comment: rateReceived.Comment ?? undefined, CarrierID: rateReceived.CarrierID});

            setData([...data]);
            window.alert(translator.decode("carrier.message.add"));
        })
        .catch(_ => {
            window.alert(translator.decode("carrier.message.errorAdd"));
        });
    };

    return (
        <Fragment>
            <div className="p-3">
                <TitlePage title={translator.decode("dynamic.carrier")} backLink={RouterPathResolver.pathHome.path} action={myActions.current}/>
                {/* <ManageCarrier data={data} setData={setData} selectCarrierOpt={selectCarrierOpt} options={options} /> */}
                <div className={`d-flex flex-direction-row ${Styles.carrierSelection}`}>
                    <div className={`${Styles.rateSelect}`}>
                        <span>{translator.decode("carrier.list.headers.carrier")}</span>
                        <Select options={selectCarrierOpt.current} isClearable={true} onChange={(e) => setCarrierOption(e)}/>
                    </div>
                    <div className={`${Styles.rateSelect}`}>
                        <span>{translator.decode("carrier.list.headers.StartingState")}</span>
                        <Select options={options.current} isClearable={true} value={startingOption} onChange={(e) => setStartingOption(e)} />
                    </div>
                    <div className={`${Styles.rateSelect}`}>
                        <span>{translator.decode("carrier.list.headers.EndingState")}</span>
                        <Select options={options.current} isClearable={true} value={endingOption} onChange={(e) => setEndingOption(e)} />
                    </div>
                    {
                        carrierOption !== null && startingOption !== null && endingOption !== null ?
                            <CreateRate carrierID={carrierOption?.value} startingState={startingOption?.value} endingState={endingOption?.value} {...findRate(carrierOption?.value, startingOption?.value, endingOption?.value)} handleCreateRate={handleCreateRate} handleDeleteRate={handleDeleteRate}/>
                        : null
                    }
                </div>
            </div>
            <div>
                <CarrierTable data={data} carrierName={carrierOption?.label} startingState={startingOption?.value} endingState={endingOption?.value} />
                {/* <Table cursor={false} rows={buildMyRows()} header={rowsData[0].map(item => {return <TableRow style={{width: "2rem", textAlign:"center"}}> {item.toString()}</TableRow>})} nbTotalElement={data.length} pagination={false} /> */}
            </div>
            <ModalCarrier title={translator.decode("carrier")} show={showModalCarrier} onClose={closeCarrierModal} closeModal={closeCarrierModal} />
        </Fragment>
    )

}

export default CarrierList;
