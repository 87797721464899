/*
 * File: SelectCommercial.tsx
 * Project: autobuy-back-office
 * File Created: Friday, 8th September 2023 04:19:23
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Wednesday, 4th October 2023 01:48:11
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import { FormControl, MenuItem, Select, InputLabel } from "@mui/material";
import translator from "MultiLanguage/TranslationWrapper";
import UserService from "Services/Users/UserService";
import { UserSimple } from "Type/user.type";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import Styles from "Content/User/Commercials/SelectCommercial.module.css";

type SelectCommercialProps = {
    CommercialID?: string;
    className?: string;
    valueName: string;
    onUpdate: Function;
    label: string;
}

const SelectCommercial = forwardRef((props: SelectCommercialProps, ref?) => {
    const [commercial, setCommercial] = useState<UserSimple>();
    const [commercialList, setCommercialList] = useState<UserSimple[]>();

    const findUserByID = (userID: string, userList?: UserSimple[]) => {
        if (!userList || userList.length === 0)
            return undefined;
        let user = userList.find((val) => val.id === userID);
        if (user && user.id !== props.CommercialID) {
            if (!ref) {
                props.onUpdate({[props.valueName]: user.id});
            } else {
                props.onUpdate();
            }
        }
        return user;
    }

    useImperativeHandle(ref, () => {
        props.onUpdate();
        return commercial?.id;
    }, [commercial]);

    useEffect(() => {
        UserService.getCommercials().then(
            (val: UserSimple[]) => {
                if (props.CommercialID)
                    setCommercial(findUserByID(props.CommercialID, val));
                setCommercialList(val);
            }
        ).catch(reason => {
            console.error(`Unable to load commercials: ${reason.message}`);
        })
    }, []);

    return (
        <FormControl size="small" className={`${Styles.formfield}`}>
            <InputLabel>{props.label}</InputLabel>
            <Select className={`${props.className} text-capitalize`} sx={{width:"100%"}} value={commercial ? commercial.id : ""} label={props.label} onChange={(e) => {setCommercial(findUserByID(e.target.value, commercialList))}}>
                <MenuItem value="">
                    <em>{translator.decode("dynamic.noAttrib")}</em>
                </MenuItem>
                {
                    commercialList ? commercialList.map(v =>
                        <MenuItem key={`role-${v.id}`} value={v.id} className="text-capitalize">
                            {`${v.FirstName} ${v.LastName}`}
                        </MenuItem>)
                        : null
                }
            </Select>
        </FormControl>
    )
})

export default SelectCommercial;
