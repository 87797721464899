/*
 * File: CarrierTable.tsx
 * Project: autobuy-back-office
 * File Created: Friday, 22nd September 2023 04:23:49
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Thursday, 30th May 2024 11:15:53
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import { useEffect, useMemo, useState } from "react";
import Tooltip from '@mui/material/Tooltip';
import Table from "Content/Table/Table";
import { RateNCarrierType} from "Type/rate.type"
import { CarrierType } from "Type/carrier.type";
import translator from "MultiLanguage/TranslationWrapper";
import Style from "Content/Carrier/CarrierTable.module.css"
import { TableCell, TableRow } from "@mui/material";

type CarrierTableProps = {
    data: CarrierType[];
    carrierName?: string;
    startingState?: number;
    endingState?: number;
}

const toolTip = (data: RateNCarrierType[]) => {
    var myToolTip: JSX.Element[] = [];
    for (let i = 0; i < data.length; ++i)
        myToolTip.push(<div key={data[i].CarrierName + i}> {data[i].CarrierName}: {data[i].Cost}€ HT  {data[i].Comment ? <span> | {data[i].Comment}</span>: null } </div>);
    return myToolTip;
};

const zeroPad = (num: number, places: number) => String(num).padStart(places, '0');

const buildMyRows = (rowsData: RateNCarrierType[], props: {carrierName?: string, startingState?: number, endingState?: number}, setHeaders: Function) => {
    let filteredData = rowsData.filter((rate) => props.endingState ? rate.EndingState === props.endingState : true)
                                .filter((rate) => props.startingState ? rate.StartingState === props.startingState : true)
                                .filter((rate) => props.carrierName ? rate.CarrierName === props.carrierName : true);
    let uniqueStartingStates: Set<string> = new Set();
    let startingStates: string[] = [];
    if (props.startingState)
        uniqueStartingStates = new Set([zeroPad(props.startingState, 2)]);
    else
        filteredData.map((val) => uniqueStartingStates.add(zeroPad(val.StartingState, 2)));
    startingStates = [...uniqueStartingStates].sort()
    let uniqueEndingStates: Set<string> = new Set();
    let endingStates: string[] = [];
    if (props.endingState)
        uniqueEndingStates = new Set([zeroPad(props.endingState, 2)]);
    else
        filteredData.map((val) => uniqueEndingStates.add(zeroPad(val.EndingState, 2)));
    endingStates = [...uniqueEndingStates].sort();
    setHeaders([<TableCell className={`${Style.cell}`} key={"header-main-title"}>{`🠣 ${translator.decode("carrier.list.headers.StartingState")} \\ ${translator.decode("carrier.list.headers.EndingState")} 🠢`}</TableCell>, ...endingStates.map((val) => <TableCell key={`header-${val}`} className={`${Style.cell}`} >{val}</TableCell>)]);
    let myRows: JSX.Element[] = [];
    for (let start of startingStates) {
        let myRowData: JSX.Element[] = [];
        myRowData.push(<TableCell style={{textAlign:"center"}} key={`row-${start}`}>{start}</TableCell>);
        for (let end of endingStates) {
            if (filteredData.filter((data) => zeroPad(data.StartingState, 2) === start && zeroPad(data.EndingState, 2) === end).length == 0)
                myRowData.push(<TableCell key={`data-${start}.${end}`}></TableCell>);
            else {
                let rates = filteredData.filter((val) => zeroPad(val.StartingState, 2) == start && zeroPad(val.EndingState, 2) == end);
                myRowData.push(<Tooltip key={`rate-${start}.${end}`} style={{cursor:"help", backgroundColor:"aqua", textAlign:"center"}} arrow={true} followCursor={true} title={toolTip(rates)} ><TableCell>{Math.max(...rates.map(val => parseInt(val.Cost)))}</TableCell></Tooltip>);
            }
        }
        myRows.push(<TableRow key={`from-row-${start}`}>{myRowData}</TableRow>);
    }
    return myRows;
}

const CarrierTable = (props: CarrierTableProps) => {
    const [rowsData, setRowsData] = useState<RateNCarrierType[]>([]);
    const [headers, setHeaders] = useState<JSX.Element[]>([]);

    const myRows = useMemo(() => {
        return buildMyRows(rowsData, props, setHeaders);
    }, [rowsData, props.endingState, props.startingState, props.carrierName]);

    useEffect(() => {
        let myData: RateNCarrierType[] = [];
        for (let carrier of props.data) {
            let rates: RateNCarrierType[] = carrier.Rates.map((val) => {
                return {
                        StartingState: val.StartingState,
                        CarrierName: carrier.Name,
                        Cost:val.Cost,
                        Comment: val.Comment,
                        EndingState: val.EndingState
                    }
                }
            );
            myData.push(...rates);
        }
        setRowsData(myData);
    }, [props.data]);

    return (
        <Table cursor={false} rows={myRows} header={headers} nbTotalElement={props.data.length} pagination={false} />
    );
}

export default CarrierTable;
