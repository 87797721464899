/*
 * File: user.utils.ts
 * Project: autobuy-back-office
 * File Created: Wednesday, 13th September 2023 11:55:38
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Tuesday, 19th September 2023 12:30:00
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import { UserCreationIntel } from "Type/user.type";

export const tranformFromUserToFormData = (user: UserCreationIntel) => {
    const form = new FormData();
    form.append("Groups", user.Groups.join(","))
    form.append("LastName", user.LastName)
    form.append("FirstName", user.FirstName)
    form.append("Email", user.Email)
    form.append("Address", user.Address ?? "")
    form.append("City", user.City ?? "")
    form.append("PostalCode", user.PostalCode.toString())
    form.append("State", user.State ?? "")
    form.append("Country", user.Country ?? "")
    form.append("LanguageID", user.LanguageID)
    form.append("Role", user.Role)
    form.append("Phone", user.Phone ?? "")
    form.append("CellPhone", user.CellPhone ?? "")
    if (user.Role === "CLIENT") {
        form.append("CommercialID", user.CommercialID!)
        form.append("IdentityFile", user.IDFile!)
        form.append("KBIS", user.Company.KBIS!)
        form.append("Company_Name", user.Company.Name!)
        form.append("Company_Country", user.Company.Country ?? "")
        form.append("Company_StandardPhoneNumber", user.Company.StandardPhoneNumber!)
        form.append("Company_Address", user.Company.Address ?? "")
        form.append("Company_City", user.Company.City ?? "")
        form.append("Company_PostalCode", user.Company.PostalCode!.toString())
        form.append("Company_State", user.Company.State ?? "")
        if (user.FlyingCommercialID)
            form.append("CommercialID", user.FlyingCommercialID!)
    } else {
        form.append("Password", user.Password!)
        form.append("Company_ID", user.Company.id!)
    }

    return form;
}
