/*
 * File: VisualService.ts
 * Project: autobuy-back-office
 * File Created: Friday, 4th August 2023 11:44:28
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Thursday, 7th September 2023 06:48:07
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import axios  from "axios";
import Config from "Config/Config";
import { RespVisualsDbType } from "Type/visuals.type";
import { buildAxiosHeader, buildAxiosHeaderFile } from "Utils/Request.utils";

export default class VisualService {
    static async createVisual(data: {name: string, collectionID: string, visual?: File, url?: URL}) {
        const url = `${Config.BACKEND.BASEURL}${Config.BACKEND.PATHS.VISUALS}`;
        const config = buildAxiosHeaderFile();
        return await axios.post(url, data, config);
    }

    static async getVisuals(name: string) {
        const url = `${Config.BACKEND.BASEURL}${Config.BACKEND.PATHS.COLLECTIONS}/${name}`;
        const config = buildAxiosHeader();
        return axios.get<RespVisualsDbType>(url, config);
    }

}
