/*
 * File: CustomerPage.tsx
 * Project: autobuy-back-office
 * File Created: Thursday, 31st August 2023 03:22:45
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Wednesday, 11th October 2023 02:00:50
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import { useState, Fragment, useEffect } from "react";
import { useLoaderData } from "react-router-dom";
import ModalUser from "Content/Modals/ModalUser/ModalUser";
import TitlePage from "Content/HeadingPage/TitlePage/TitlePage";
import translator from "MultiLanguage/TranslationWrapper";
import RouterPathResolver from "Config/Router/RouterPathResolver";
import UserList from "./UserList";

function CustomerPage(_: any) {
    const loadData = useLoaderData() as Promise<any>[];
    const [showModalUser, setShowModalUser] = useState(false);
    const [valid, setValid] = useState<{myUsers: [], nb_page: number}>({myUsers: [], nb_page:0});
    const [notValid, setNotValid] = useState<{myUsers: [], nb_page: number}>({myUsers: [], nb_page:0});
    const [archived, setArchived] = useState<{myUsers: [], nb_page: number}>({myUsers: [], nb_page:0});

    useEffect(() => {
        Promise.all(loadData).then((values: {myUsers: [], nb_page: number}[]) => {
            setValid(values[0]);
            setNotValid(values[1]);
            setArchived(values[2]);
        });
    }, [loadData]);

    const handleCreateUser = (e: any) => {
        e.stopPropagation();
        setShowModalUser(true);
    };

    const handleToggleUserModal = (e: React.MouseEvent<HTMLElement>) => {
        setShowModalUser(false);
    };

    return (
        <Fragment>
            <div className="p-3">
                <TitlePage title={translator.decode("users.title")} backLink={RouterPathResolver.pathHome.path} action={<button className="btn btn-primary me-3" onClick={handleCreateUser}>{translator.decode("dynamic.create")}</button>}/>
            </div>
            <div className="p-3">
                <UserList titleKey={"dynamic.NEW&PENDING"} Users={notValid.myUsers} nbPage={notValid.nb_page} pagination={false} actions={true} />
                <UserList titleKey={"dynamic.VALID"} Users={valid.myUsers} nbPage={valid.nb_page} botPagination={true} actions={true} />
                <UserList titleKey={"dynamic.archived"} Users={archived.myUsers} nbPage={archived.nb_page} pagination={false} actions={true} />
            </div>
            <ModalUser title={translator.decode("dynamic.users")} show={showModalUser} onClose={handleToggleUserModal} closeModal={handleToggleUserModal} isClient={true} />
        </Fragment>
    );
}

export default CustomerPage;
