/*
 * File: CustomerPage.tsx
 * Project: autobuy-back-office
 * File Created: Thursday, 31st August 2023 03:22:45
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Monday, 11th September 2023 01:28:54
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */

import { Fragment, useEffect, useState } from "react";
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import TitlePage from "Content/HeadingPage/TitlePage/TitlePage";
import { LoadedMyUsersData } from "Type/user.type";
import translator from "MultiLanguage/TranslationWrapper";
import RouterPathResolver from "Config/Router/RouterPathResolver";
import UserList from "./UserList";

function MyCustomerPage(_: any) {
    const loadData = useLoaderData() as LoadedMyUsersData;
    // const navigate = useNavigate();
    // const location = useLocation();
    // const navigate = useNavigate();
    // const [user, setUser] = useState<{myUsers: [], nb_page: number}>({myUsers: [], nb_page:0});

    // const handleOrderBy = ( event: React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement> ) => {
    //     const searchParams = new URLSearchParams(location.search);
    //     searchParams.set("page", (1).toString());
    //     searchParams.set("orderedby", (`${event.target.value},asc`));
    //     navigate("?" + searchParams.toString());
    // };

    return (
        <Fragment>
            <div className="p-3">
                <TitlePage title={translator.decode("users.myUsers")} backLink={RouterPathResolver.pathHome.path} />
            </div>
            {/* <div className="p-2 pb-1">
                <span>{translator.decode("dynamic.OrderBy")}</span>
                <select name="orderedbyOption" id="orderedbyOption" onChange={(e)=> handleOrderBy(e)}>
                        <option value="LastName">{translator.decode("users.list.headers.LastName")}</option>
                        <option value="FirstName">{translator.decode("users.list.headers.Firstname")}</option>
                        <option value="Company">{translator.decode("users.list.headers.Company")}</option>
                        <option value="CommercialID">{translator.decode("users.list.headers.Commercial")}</option>
                        <option value="CreatedAt">{translator.decode("users.list.headers.CreatedAt")}</option>
                        <option value="Status">{translator.decode("users.list.headers.Status")}</option>
                </select>
            </div> */}
            <div className="p-3">
                <UserList Users={loadData.myUsers} nbPage={loadData.nb_page} botPagination={true} actions={false} />
            </div>
        </Fragment>
    );
}

export default MyCustomerPage;
